var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.template = site.template || {};

(function ($) {
  // $(document).on('inv_status_data:updated', '.js-product', function(e) {
  //   var $statusList = $('.js-inv-status-list', $(this));
  //   var skuBaseId = $statusList.data("sku-base-id");
  //   var skuData = prodcat.data.getSku(skuBaseId);
  //   if (!!skuData) {
  //     Drupal.behaviors.inventoryStatusV1.update($statusList, skuData);
  //   }
  // });
  // 
  // $(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
  //   var $statusList = $('.js-inv-status-list', $(this));
  //   if ($statusList.length < 1) return null;
  //   Drupal.behaviors.inventoryStatusV1.selectSku($statusList, skuBaseId);
  // });

  Drupal.behaviors.sppStickyV1 = {
    attach: function (context, settings) {
      var isDesktop = !!settings && !settings.is_mobile;

      if ($('.site-content .spp').length && isDesktop) {
        this.init();
        var addBtnPos = $('.product--full .js-product-cta').position().top + 200;
        var $sticky = $('header .site-header__addtobag');
        var $window = $(window);

        applySelectBoxAccessibility($sticky);
        _scroll = _.debounce(function () {
          if ($window.scrollTop() >= addBtnPos) {
            $sticky.addClass('scrolled-past');
            // $sticky.slideDown();
          } else {
            $sticky.removeClass('scrolled-past');
            // $sticky.slideUp();
          }
        }, 200);
        $window.on('scroll', _scroll);
        // $('header .site-header__addtobag__image').on('click', function() {
        //   $("html, body").animate({ scrollTop: 0 }, 300);
        // });
      }
    },
    init: function () {
      var prodId = $('.js-product').data('product-id');
      // console.log('prodId', prodId);
      var prodData = prodcat.data.getProduct(prodId);

      if (prodData && prodData.PROD_RGN_NAME && prodData.PROD_RGN_NAME.length > 25) {
        // trim PROD_RGN_NAME if longer than 20 characters, add ellipsis
        prodData.PROD_RGN_NAME = prodData.PROD_RGN_NAME.substring(0, 25) + ' ...';
      }
      var html = site.template.get({
        name: 'spp_sticky_v1',
        data: prodData
      });

      $('.js-site-header-sticky').append(html);
      $('.site-header__addtobag .js-sku-menu').selectBox();
      if (typeof skuSelectedId !== 'undefined') { // TODO: fix this soon; this code (added in commit ee43566b289f) won't ever be reached, as skuSelectedId isn't defined anywhere here
        $('.js-product').trigger('product.skuSelect', [skuSelectedId, 'url_hit']);
      }
    }
  };
})(jQuery);
